'use strict';

$(".rrm-home-header__slides").slick({
    //infinite: true,
    lazyLoad: 'progressive',
    useTransform: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: false

});

$('.rrm-home-flex-container__parent-featureditems').slick({
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    mobileFirst: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [{
        breakpoint: 600,
        settings: 'unslick'
    }]
});

$(window).on('resize', function () {
    $('.rrm-home-flex-container__parent-featureditems').slick('resize');
});